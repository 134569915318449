<template>
    <client-page>
        <sub-visual-intro />

        <section class="section">
            <v-container>
                <tab-intro />

                <div class="historys">
                    <!-- S:: 2023 -->
                    <div class="history history--left">
                        <v-row class="row--x-large" align="start">
                            <v-col cols="12" md="6" class="history__year-wrap">
                                <h3 class="history__year">
                                    2023
                                </h3>
                            </v-col>
                            <v-col cols="12" md="6" class="history__contents-wrap">
                                <div class="history__contents">
                                    <div class="history__row">
                                        <p class="history__month">
                                            01
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                화성시 동탄어울림종합사회복지관 수탁
                                            </p>
                                            <p class="history__detail">
                                                FLCM 업무 협약 진행(미얀마 및 해외 취약계층 지원)
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            03
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                GOODTV 기독교복음방송 업무 협약 진행(다양한 선한 복지활동 실천)
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            04
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                창립기념일 수원화성행궁 단합 걷기 진행
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            06
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                홍보대사 위촉식 진행(배우 : 권해성)
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            08
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                산하시설 시설장 워크숍
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            09
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                성북구 어르신의 건강을 위한 행복 밥상 나눔행사 진행(삼계탕 200인분)
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            10
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                노사발전재단 ‘노사파트너십 프로그램 지원사업’ 선정
                                            </p>
                                            <p class="history__detail">
                                                노사협의회 활성화 및 노사 비전수립 워크숍 진행(es제천리조트)
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            11
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                노사협의회 시설장/중간관리자 간담회
                                            </p>
                                            <p class="history__detail">
                                                노사협의회 ‘노사 소통활성화 활동’(청계산 산행)
                                            </p>
                                            <p class="history__detail">
                                                노사협의회 ‘노사가 함께하는 사회공헌 활동’ (저소득층을 위한 김장 나눔 행사)
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            12
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                취약 계층 지원을 위한 김치 3kg 550세트 및 쌀 10kg 100포 성품 전달 (성북구청)
                                            </p>
                                            <p class="history__detail">
                                                성북구청장 감사장 수상
                                            </p>
                                            <p class="history__detail">
                                                광명아동보호전문기관 수탁
                                            </p>
                                            <p class="history__detail">
                                                오산대학교 업무 협약 진행(전문인재 양성을 위한 산하 협력)
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- E:: 2023 -->

                    <!-- S:: 2022 -->
                    <div class="history history--right">
                        <v-row class="row--x-large" align="start">
                            <v-col cols="12" md="6" class="history__year-wrap">
                                <h3 class="history__year">
                                    2022
                                </h3>
                            </v-col>
                            <v-col cols="12" md="6" class="history__contents-wrap">
                                <div class="history__contents">
                                    <div class="history__row">
                                        <p class="history__month">
                                            01
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                사회복지 기관 이용인과 소외계층 팥죽나눔행사(보문사 협력사업) 사회복지 기관 이용인과 소외계층 팥죽나눔행사(보문사 협력사업) 사회복지 기관 이용인과 소외계층 팥죽나눔행사(보문사 협력사업)
                                            </p>
                                            <p class="history__detail">
                                                여성노숙인 쉼터(열린여성센터) 의류지원
                                            </p>
                                            <p class="history__detail">
                                                저소득 시니어 겨울용품(양말 등) 지원
                                            </p>
                                            <p class="history__detail">
                                                강남구 자곡다함께키움센터 위탁기관 선정
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            03
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                아동보호전문기관(경기도 광주, 파주) 추진전략 및 발전방향성 회의 진행
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            04
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                법인 산하시설장 연합 워크숍 (미래전략 논의 연수)
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            05
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                에듀윌ESG위원회 쌀 나눔 기증식 (100포)
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            06
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                강북구50플러스센터 위탁기관 선정
                                            </p>
                                            <p class="history__detail">
                                                영등포구 시니어클럽 위탁기관
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            07
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                강남지역자활센터 위탁기관 선정
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            10
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                용인시 기흥노인복지관 위탁기관 선정
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- E:: 2022 -->

                    <!-- S:: 2020~2021 -->
                    <div class="history history--left">
                        <v-row class="row--x-large" align="start">
                            <v-col cols="12" md="6" class="history__year-wrap">
                                <h3 class="history__year">
                                    2020~2021
                                </h3>
                            </v-col>
                            <v-col cols="12" md="6" class="history__contents-wrap">
                                <div class="history__contents">
                                    <div class="history__row">
                                        <p class="history__month">
                                            20
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                어르신일자리사업 일상생활지원센터 운영
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            21
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                성북50플러스센터 재위탁 운영
                                            </p>
                                            <p class="history__detail">
                                                지역혁신계획 공모사업 선정 (AI로봇치매돌봄 민관네트워크사업)
                                            </p>
                                            <p class="history__detail">
                                                서울시 제로웨이스트 공모사업 선정 (자원순환교육)
                                            </p>
                                            <p class="history__detail">
                                                광진구 구의2동키움센터 수탁
                                            </p>
                                            <p class="history__detail">
                                                광명시니어클럽 수탁
                                            </p>
                                            <p class="history__detail">
                                                강동구 천호2동 키움센터 수탁
                                            </p>
                                            <p class="history__detail">
                                                서초구 학대피해아동쉼터 수탁
                                            </p>
                                            <p class="history__detail">
                                                경기 파주 아동보호전문기관 수탁
                                            </p>
                                            <p class="history__detail">
                                                경기 광주 아동보호전문기관 수탁
                                            </p>
                                            <p class="history__detail">
                                                지역행사 '2021 두근두근 별빛마켓(소외계층을 위한 프리마켓)' 진행
                                            </p>
                                            <p class="history__detail">
                                                지역사회 나눔행사 '노(老)노(NO) 성북THE중년' 행사 진행
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- E:: 2020~2021 -->

                    <!-- S:: 2010~2019 -->
                    <div class="history history--right">
                        <v-row class="row--x-large" align="start">
                            <v-col cols="12" md="6" class="history__year-wrap">
                                <h3 class="history__year">
                                    2010~2019
                                </h3>
                            </v-col>
                            <v-col cols="12" md="6" class="history__contents-wrap">
                                <div class="history__contents">
                                    <div class="history__row">
                                        <p class="history__month">
                                            15
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                구립대흥어린이집 위탁 운영
                                            </p>
                                            <p class="history__detail">
                                                구립포레스타2 어린이집 위탁운영
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            18
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                성북시니어클럽위탁 운영
                                            </p>
                                            <p class="history__detail">
                                                성북50플러스센터 위탁 운영
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            19
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                지역주민참여 축제 '이토록 멋진마을' 행사 진행
                                            </p>
                                            <p class="history__detail">
                                                치매전문AI로봇을 통한 사회공헌활동 진행 쌀 나눔 기증식 (100포)
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- E:: 2010~2019 -->

                    <!-- S:: 2001~2009 -->
                    <div class="history history--left">
                        <v-row class="row--x-large" align="start">
                            <v-col cols="12" md="6" class="history__year-wrap">
                                <h3 class="history__year">
                                    2001~2009
                                </h3>
                            </v-col>
                            <v-col cols="12" md="6" class="history__contents-wrap">
                                <div class="history__contents">
                                    <div class="history__row">
                                        <p class="history__month">
                                            01
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                서울시사회복지관 평가우수프로그램 선정
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            03
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                서초구 우면문화센터 두레쉼터 개소
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            04
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                구립 고은어린이집 위탁 운영
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            07
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                간병인 바우뫼복지문화회관 위탁 운영
                                            </p>
                                            <p class="history__detail">
                                                재가노인복지센터 개원
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            08
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                에듀윌ESG위원회 쌀 나눔 기증식 (100포)
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            09
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                간병인파견 자활근로사업 실시
                                            </p>
                                            <p class="history__detail">
                                                서울시 노인특화사업시설 지정
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- E:: 2001~2009 -->

                    <!-- S:: 1980~2000 -->
                    <div class="history history--right">
                        <v-row class="row--x-large" align="start">
                            <v-col cols="12" md="6" class="history__year-wrap">
                                <h3 class="history__year">
                                    1980~2000
                                </h3>
                            </v-col>
                            <v-col cols="12" md="6" class="history__contents-wrap">
                                <div class="history__contents">
                                    <div class="history__row">
                                        <p class="history__month">
                                            89
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                사회복지법인 설립
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            92
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                서울시 은빛고령자취업 알선센터 위탁운영
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            94
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                서울시 우면사회복지관 위탁 운영
                                            </p>
                                        </div>
                                    </div>
                                    <div class="history__row">
                                        <p class="history__month">
                                            95
                                        </p>
                                        <div class="history__details">
                                            <p class="history__detail">
                                                구립 우면어린이집 개원
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- E:: 1980~2000 -->
                </div>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisualIntro from "@/components/client/sub/sub-visual-intro.vue";
import TabIntro from "@/components/client/sub/tab-intro.vue";
export default {
    components: {
        ClientPage,
        SubVisualIntro,
        TabIntro,
    },
};
</script>

<style lang="scss" scoped>
.historys {
    .history:not(:last-child) {
        margin-bottom: 40px;
    }
}
.history__year {
    font-size: 2.8rem;
    line-height: 1;
    font-weight: 700;
    margin-bottom: -8px;
}
.history__row {
    display: flex;
    line-height: 1.5;
    font-size: 1.4rem;
    .history__month {
        font-weight: 500;
        min-width: 24px;
    }
    .history__detail {
        color: #666;
        position: relative;
        padding-left: 8px;
        &::after {
            content: "·";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}
.history__details {
    .history__detail:not(:last-child) {
        margin-bottom: 6px;
    }
}
.history__contents {
    .history__row:not(:last-child) {
        margin-bottom: 6px;
    }
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
    .history__year {
        font-size: 3.8rem;
        margin-bottom: 0;
    }
    .history--right {
        .history__year {
            text-align: right;
        }
    }
    .history--left {
        .row {
            flex-direction: row-reverse;
        }
        .history__contents {
            text-align: right;
            .history__row {
                flex-direction: row-reverse;
                .history__detail {
                    padding-left: 0;
                    padding-right: 8px;
                    &::after {
                        left: unset;
                        right: 0;
                    }
                }
            }
        }
    }
    .history__contents {
        padding-top: 10px;
        position: relative;
    }
    .history__contents:not(.historys .history:last-child .history__contents)::before {
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        height: calc(100% + 50px);
        top: 20px;
        left: -10px;
        background-color: #d9d9d9;
    }

    .history__year-wrap {
        position: relative;
        z-index: 2;
    }
    .history__year-wrap::after {
        content: "";
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        background-color: var(--v-primary-base);
        top: 50%;
        right: 0;
        transform: translate(50%, -50%);
    }
    .history--left {
        .history__year-wrap::after {
            right: unset;
            left: 0;
            transform: translate(-50%, -50%);
        }
        .history__contents:not(.historys .history:last-child .history__contents)::before {
            left: unset;
            right: -11px;
        }
    }
}
@media (min-width: 1024px) {
    .historys {
        .history:not(:last-child) {
            margin-bottom: 50px;
        }
    }
    .history__year {
        font-size: 4.8rem;
    }
    .history__row {
        font-size: 1.6rem;
        .history__month {
            min-width: 30px;
        }
    }
    .history__details {
        .history__detail {
            padding-left: 12px;
        }
        .history__detail:not(:last-child) {
            margin-bottom: 12px;
        }
    }
    .history__contents:not(.historys .history:last-child .history__contents)::before {
        height: calc(100% + 90px);
        top: 25px;
        left: -40px;
    }
    .history--left {
        .history__contents {
            .history__row {
                .history__detail {
                    padding-right: 12px;
                }
            }
        }
        .history__contents:not(.historys .history:last-child .history__contents)::before {
            right: -41px;
        }
    }
    .history__contents {
        .history__row:not(:last-child) {
            margin-bottom: 12px;
        }
    }

    .history__year-wrap::after {
        width: 8px;
        height: 8px;
    }
}
@media (min-width: 1200px) {
}
</style>
