var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual-intro'), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('tab-intro'), _c('div', {
    staticClass: "historys"
  }, [_c('div', {
    staticClass: "history history--left"
  }, [_c('v-row', {
    staticClass: "row--x-large",
    attrs: {
      "align": "start"
    }
  }, [_c('v-col', {
    staticClass: "history__year-wrap",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h3', {
    staticClass: "history__year"
  }, [_vm._v(" 2023 ")])]), _c('v-col', {
    staticClass: "history__contents-wrap",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "history__contents"
  }, [_c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 01 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 화성시 동탄어울림종합사회복지관 수탁 ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" FLCM 업무 협약 진행(미얀마 및 해외 취약계층 지원) ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 03 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" GOODTV 기독교복음방송 업무 협약 진행(다양한 선한 복지활동 실천) ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 04 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 창립기념일 수원화성행궁 단합 걷기 진행 ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 06 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 홍보대사 위촉식 진행(배우 : 권해성) ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 08 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 산하시설 시설장 워크숍 ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 09 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 성북구 어르신의 건강을 위한 행복 밥상 나눔행사 진행(삼계탕 200인분) ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 10 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 노사발전재단 ‘노사파트너십 프로그램 지원사업’ 선정 ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 노사협의회 활성화 및 노사 비전수립 워크숍 진행(es제천리조트) ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 11 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 노사협의회 시설장/중간관리자 간담회 ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 노사협의회 ‘노사 소통활성화 활동’(청계산 산행) ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 노사협의회 ‘노사가 함께하는 사회공헌 활동’ (저소득층을 위한 김장 나눔 행사) ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 12 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 취약 계층 지원을 위한 김치 3kg 550세트 및 쌀 10kg 100포 성품 전달 (성북구청) ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 성북구청장 감사장 수상 ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 광명아동보호전문기관 수탁 ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 오산대학교 업무 협약 진행(전문인재 양성을 위한 산하 협력) ")])])])])])], 1)], 1), _c('div', {
    staticClass: "history history--right"
  }, [_c('v-row', {
    staticClass: "row--x-large",
    attrs: {
      "align": "start"
    }
  }, [_c('v-col', {
    staticClass: "history__year-wrap",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h3', {
    staticClass: "history__year"
  }, [_vm._v(" 2022 ")])]), _c('v-col', {
    staticClass: "history__contents-wrap",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "history__contents"
  }, [_c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 01 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 사회복지 기관 이용인과 소외계층 팥죽나눔행사(보문사 협력사업) 사회복지 기관 이용인과 소외계층 팥죽나눔행사(보문사 협력사업) 사회복지 기관 이용인과 소외계층 팥죽나눔행사(보문사 협력사업) ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 여성노숙인 쉼터(열린여성센터) 의류지원 ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 저소득 시니어 겨울용품(양말 등) 지원 ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 강남구 자곡다함께키움센터 위탁기관 선정 ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 03 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 아동보호전문기관(경기도 광주, 파주) 추진전략 및 발전방향성 회의 진행 ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 04 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 법인 산하시설장 연합 워크숍 (미래전략 논의 연수) ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 05 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 에듀윌ESG위원회 쌀 나눔 기증식 (100포) ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 06 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 강북구50플러스센터 위탁기관 선정 ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 영등포구 시니어클럽 위탁기관 ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 07 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 강남지역자활센터 위탁기관 선정 ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 10 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 용인시 기흥노인복지관 위탁기관 선정 ")])])])])])], 1)], 1), _c('div', {
    staticClass: "history history--left"
  }, [_c('v-row', {
    staticClass: "row--x-large",
    attrs: {
      "align": "start"
    }
  }, [_c('v-col', {
    staticClass: "history__year-wrap",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h3', {
    staticClass: "history__year"
  }, [_vm._v(" 2020~2021 ")])]), _c('v-col', {
    staticClass: "history__contents-wrap",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "history__contents"
  }, [_c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 20 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 어르신일자리사업 일상생활지원센터 운영 ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 21 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 성북50플러스센터 재위탁 운영 ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 지역혁신계획 공모사업 선정 (AI로봇치매돌봄 민관네트워크사업) ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 서울시 제로웨이스트 공모사업 선정 (자원순환교육) ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 광진구 구의2동키움센터 수탁 ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 광명시니어클럽 수탁 ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 강동구 천호2동 키움센터 수탁 ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 서초구 학대피해아동쉼터 수탁 ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 경기 파주 아동보호전문기관 수탁 ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 경기 광주 아동보호전문기관 수탁 ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 지역행사 '2021 두근두근 별빛마켓(소외계층을 위한 프리마켓)' 진행 ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 지역사회 나눔행사 '노(老)노(NO) 성북THE중년' 행사 진행 ")])])])])])], 1)], 1), _c('div', {
    staticClass: "history history--right"
  }, [_c('v-row', {
    staticClass: "row--x-large",
    attrs: {
      "align": "start"
    }
  }, [_c('v-col', {
    staticClass: "history__year-wrap",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h3', {
    staticClass: "history__year"
  }, [_vm._v(" 2010~2019 ")])]), _c('v-col', {
    staticClass: "history__contents-wrap",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "history__contents"
  }, [_c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 15 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 구립대흥어린이집 위탁 운영 ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 구립포레스타2 어린이집 위탁운영 ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 18 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 성북시니어클럽위탁 운영 ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 성북50플러스센터 위탁 운영 ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 19 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 지역주민참여 축제 '이토록 멋진마을' 행사 진행 ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 치매전문AI로봇을 통한 사회공헌활동 진행 쌀 나눔 기증식 (100포) ")])])])])])], 1)], 1), _c('div', {
    staticClass: "history history--left"
  }, [_c('v-row', {
    staticClass: "row--x-large",
    attrs: {
      "align": "start"
    }
  }, [_c('v-col', {
    staticClass: "history__year-wrap",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h3', {
    staticClass: "history__year"
  }, [_vm._v(" 2001~2009 ")])]), _c('v-col', {
    staticClass: "history__contents-wrap",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "history__contents"
  }, [_c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 01 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 서울시사회복지관 평가우수프로그램 선정 ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 03 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 서초구 우면문화센터 두레쉼터 개소 ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 04 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 구립 고은어린이집 위탁 운영 ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 07 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 간병인 바우뫼복지문화회관 위탁 운영 ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 재가노인복지센터 개원 ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 08 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 에듀윌ESG위원회 쌀 나눔 기증식 (100포) ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 09 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 간병인파견 자활근로사업 실시 ")]), _c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 서울시 노인특화사업시설 지정 ")])])])])])], 1)], 1), _c('div', {
    staticClass: "history history--right"
  }, [_c('v-row', {
    staticClass: "row--x-large",
    attrs: {
      "align": "start"
    }
  }, [_c('v-col', {
    staticClass: "history__year-wrap",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h3', {
    staticClass: "history__year"
  }, [_vm._v(" 1980~2000 ")])]), _c('v-col', {
    staticClass: "history__contents-wrap",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "history__contents"
  }, [_c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 89 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 사회복지법인 설립 ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 92 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 서울시 은빛고령자취업 알선센터 위탁운영 ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 94 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 서울시 우면사회복지관 위탁 운영 ")])])]), _c('div', {
    staticClass: "history__row"
  }, [_c('p', {
    staticClass: "history__month"
  }, [_vm._v(" 95 ")]), _c('div', {
    staticClass: "history__details"
  }, [_c('p', {
    staticClass: "history__detail"
  }, [_vm._v(" 구립 우면어린이집 개원 ")])])])])])], 1)], 1)])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }